import { Helmet } from "react-helmet";
import "./PrivacyPolicy.css";
const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <Helmet>
        <title>Política de Privacidade e Termos de Uso</title>
      </Helmet>
      
      <h1>Política de Privacidade e Termos de Uso</h1>
      <p>
        Bem-vindo à <strong>PromosNinja</strong>! Esta política explica como coletamos, usamos e protegemos suas informações pessoais ao utilizar nossos serviços.
      </p>

      <h2>1. Informações que Coletamos</h2>
      <p>Coletamos as seguintes informações pessoais:</p>
      <ul>
        <li><strong>Nome</strong></li>
        <li><strong>Email</strong></li>
        <li><strong>Data de nascimento</strong></li>
        <li><strong>Endereço</strong></li>
        <li><strong>CPF</strong></li>
        <li><strong>Dados de navegação e uso do site</strong></li>
      </ul>

      <h2>2. Como Usamos Suas Informações</h2>
      <p>As informações coletadas são utilizadas para:</p>
      <ul>
        <li>Fornecer e personalizar nossos serviços</li>
        <li>Enviar atualizações e promoções</li>
        <li>Melhorar nossos produtos e serviços</li>
        <li>Realizar análises e pesquisas de mercado</li>
      </ul>

      <h3>2.2. Compartilhamento de Informações</h3>
      <p>Não compartilhamos suas informações pessoais com terceiros, exceto quando necessário para:</p>
      <ul>
        <li>Atender solicitações legais</li>
        <li>Proteger nossos direitos e segurança</li>
        <li>Operar nosso negócio com prestadores de serviço de confiança</li>
      </ul>

      <h3>2.4. Segurança das Informações</h3>
      <p>Adotamos medidas de segurança para proteger suas informações pessoais contra acesso não autorizado, alteração, divulgação ou destruição. No entanto, nenhuma transmissão de dados pela internet é 100% segura.</p>

      <h2>3. Seus Direitos</h2>
      <p>Você tem o direito de:</p>
      <ul>
        <li>Acessar suas informações pessoais</li>
        <li>Corrigir informações incorretas ou desatualizadas</li>
        <li>Solicitar a exclusão de suas informações pessoais</li>
        <li>Restringir o processamento de suas informações pessoais</li>
      </ul>

      <h2>4. Alterações nesta Política</h2>
      <p>Podemos atualizar esta política periodicamente. Recomendamos que você reveja esta página regularmente para se manter informado sobre nossas práticas de privacidade.</p>

      <h2>5. Contato</h2>
      <p>Se você tiver dúvidas sobre esta política, entre em contato:</p>
      <p>Email: <a href="mailto:ninja@promosninjas.com.br">ninja@promosninjas.com.br</a></p>
    </div>
  );
};

export default PrivacyPolicy;
