import { ArrowBackIosNew, WhatsApp } from "@mui/icons-material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import logo from '../assets/logo.png';
import ProductCard from "../components/ProductCard";
import ProductInfo from "../components/ProductInfo";
import { formatShopName } from "../utils/formatShopName";

interface Product {
  imageUrl?: string;
  title?: string;
  price?: string;
  dialogs?: string;
  coupon?: string;
  shipping?: string;
  link?: string;
  createdAt?: string;
  id: number;
}

interface InfomationProduct {
  id: any;
  link: string | undefined;
  shipping: string;
  coupon: string;
  dialogs: string;
  price: string;
  title: string;
  createdAt: string;
  imageUrl: string;
}

interface ProductLayoutProps {
  shopName: string;
  headerColor: string;
  backgroundColor: string;
  produtos: Product[];
  informacaoproduto: InfomationProduct;
  logourl: string;
}
interface ShopData {
  nomedaloja: string;
  domainName: string;
  colorHeader: string;
  backgroundColor: string;
  products: Product[];
  linkdogrupo: string;
  logourl: string;
}

const ProductLayout: React.FC<ProductLayoutProps> = ({
  shopName,
  headerColor,
  backgroundColor,
  produtos,
  informacaoproduto,
  logourl,
}) => {
  const [displayedProducts, setDisplayedProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const loaderRef = useRef<HTMLDivElement | null>(null);
  const [shopData] = useState<ShopData | null>(null);

  useEffect(() => {
    const initialItemsPerPage = 10; // ou mais, dependendo do layout
    setDisplayedProducts(
      produtos
      .sort((a, b) => {
        const dateA = a.createdAt ? new Date(a.createdAt).getTime() : 0;
        const dateB = b.createdAt ? new Date(b.createdAt).getTime() : 0;
        return dateB - dateA;
      })
      .slice(0, initialItemsPerPage)
    );
  }, [produtos]);


  const loadMoreProducts = useCallback(() => {
    if (loading) return;

    setLoading(true);

    // Simulate delay for loading more products
    setTimeout(() => {
      const itemsPerPage = 10;
      const nextPage = currentPage + 1;
      const startIndex = currentPage * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;

      // Load more products
      const moreProducts = produtos.slice(startIndex, endIndex);
      setDisplayedProducts((prev) => [...prev, ...moreProducts]);
      setCurrentPage(nextPage); // Update current page
      setLoading(false);
    }, 300);
  }, [loading, currentPage, produtos]);

  useEffect(() => {
    const handleScroll = (entries: IntersectionObserverEntry[]) => {
      const target = entries[0];
      if (target.isIntersecting && !loading) {
        loadMoreProducts();
      }
    };

    const observer = new IntersectionObserver(handleScroll, { threshold: 0.1 });

    // Use a variable to hold the current ref value
    const currentLoaderRef = loaderRef.current;

    if (currentLoaderRef) {
      observer.observe(currentLoaderRef);
    }

    return () => {
      if (currentLoaderRef) {
        observer.unobserve(currentLoaderRef);
      }
    };
  }, [loading, loadMoreProducts]);

  const formattedShopName = shopData?.nomedaloja
    ? formatShopName(shopData.nomedaloja)
    : "Acesse minha loja";

    const shareMessage = () => {
      const message = encodeURIComponent(informacaoproduto.link!);
      const whatsappUrl = `https://wa.me/?text=${message}`;
      window.open(whatsappUrl, "_blank");
    };

  return (
    <>
      <Helmet>
        <title>{formattedShopName}</title>
        <meta name="description" content={`${shopData?.nomedaloja}`} />
        <meta property="og:title" content={formattedShopName} />
        <meta property="og:image" content={shopData?.logourl} />
        <meta property="og:description" content={`${shopData?.nomedaloja}`} />
        <meta property="og:url" content={window.location.href} />
        <link rel="icon" href={shopData?.logourl} />
      </Helmet>

      <div className="bg-[#f0f2f5] min-h-screen flex flex-col justify-center items-center">
        <header className="bg-[#F67DAF] w-full py-5">
          <div className="w-full grid grid-cols-2 justify-between items-center px-5 lg:px-0 lg:max-w-[900px] lg:mx-auto">
            <Link to='/' className="flex items-center gap-2 hover:no-underline">
              <img src={logo} className="w-10 h-10" alt="Logo da Loja" />
              <h5 className="text-white font-medium">
                {formatShopName(shopName)}
              </h5>
            </Link>
          </div>
        </header>

        <div className="flex flex-col w-full lg:max-w-[900px]">
          <div className="grid grid-cols-3 bg-[#F67DAF]">
            <a href="/"
              className="text-sm text-black text-center font-semibold hover:no-underline pt-5 bg-[#f0f2f5] rounded-tl-full lg:rounded-none"
            >
              <span className="pb-1 hover:border-b-2 hover:border-blue-600">Recentes</span>
            </a>
            <a href="/"
              className="text-sm text-black text-center font-semibold hover:no-underline pt-5 bg-[#f0f2f5]"
            >
              <span className="pb-1 hover:border-b-2 hover:border-blue-600">Destaques</span>
            </a>
            <a href="/"
              className="text-sm text-black text-center font-semibold hover:no-underline pt-5 bg-[#f0f2f5] rounded-tr-full lg:rounded-none"
            >
              <span className="pb-1 hover:border-b-2 hover:border-blue-600">Cupons</span>
            </a>
          </div>
        </div>

        <div className="w-full flex justify-between items-center px-5 py-5 lg:max-w-[900px]">
          <Link to='/' className="border-2 border-black flex justify-center p-1 items-center rounded-full">
            <ArrowBackIosNew fontSize="small" className="text-black" />
          </Link>
          <div
            onClick={shareMessage}
            className="bg-green-500 border-2 border-green-500 rounded-full flex justify-center items-center p-1"
          >
            <WhatsApp fontSize="small" className="text-white" />
          </div>
        </div>

        <div className="w-full flex flex-col px-5">
          <ProductInfo
            imageUrl={informacaoproduto.imageUrl || ""}
            title={informacaoproduto?.title || ""}
            price={informacaoproduto?.price || ""}
            dialogs={informacaoproduto?.dialogs || ""}
            coupon={informacaoproduto?.coupon || ""}
            shipping={informacaoproduto?.shipping || ""}
            createdAt={informacaoproduto?.createdAt || ""}
            link={informacaoproduto?.link}
            id={informacaoproduto.id}
          />
        </div>

        <div className="grid grid-cols-1 gap-5 py-5 px-5 md:grid-cols-2 lg:max-w-[900px] lg:px-0">
          {displayedProducts.map((product, index) => (
            <ProductCard
              key={index}
              imageUrl={product.imageUrl || ""}
              title={product?.title || ""}
              price={product?.price || ""}
              dialogs={product?.dialogs || ""}
              coupon={product?.coupon || ""}
              shipping={product?.shipping || ""}
              link={product?.link}
              id={product.id}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default ProductLayout;
