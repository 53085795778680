import { ArrowForwardIos, ContentCopy, LocalActivity } from "@mui/icons-material";
import { formatDistanceToNow } from "date-fns";
import { ptBR } from "date-fns/locale";
import React, { MouseEvent, useState } from "react";
import amazonLogo from "../assets/amzn.png";
import mercadolivreLogo from "../assets/livre.png";
import magaluLogo from "../assets/magalu.png";
import NaturaLogo from "../assets/Natura.png";
import shopeeLogo from "../assets/shopee.png";

interface ProductInfoProps {
  imageUrl?: string;
  title?: string;
  price?: string;
  dialogs?: string;
  coupon?: string;
  shipping?: string;
  link?: string;
  id?: number;
  createdAt?: string;
}

const ProductInfo: React.FC<ProductInfoProps> = ({
  imageUrl,
  title,
  price,
  dialogs,
  coupon,
  shipping,
  link,
  createdAt
}) => {

  const [isCopied, setIsCopied] = useState(false);

  const storeMapping: Record<string, { logo: string; name: string }> = {
    mercadolivre: { logo: mercadolivreLogo, name: "Mercado Livre" },
    shopee: { logo: shopeeLogo, name: "Shopee" },
    magazine: { logo: magaluLogo, name: "Magalu" },
    amzn: { logo: amazonLogo, name: "Amazon" },
    amazon: { logo: amazonLogo, name: "Amazon" },
    natura: { logo: NaturaLogo, name: "Natura" },
  };
  
  const storeKey = Object.keys(storeMapping).find((key) => link?.includes(key));
  
  const { logo: logoSrc, name: store } = storeKey
    ? storeMapping[storeKey]
    : { logo: '', name: "Loja Desconhecida" };
  

  const fetchCoupon = async (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()

    await navigator.clipboard.writeText(coupon!)
    setIsCopied(true)

    setTimeout(() => {
      setIsCopied(false)
    }, 2000)
  }

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      return "";
    }
  
    return `Postado ${formatDistanceToNow(date, { addSuffix: true, locale: ptBR })}`
  };

  return (
    <div className="w-full flex flex-col bg-zinc-50 gap-5 p-4 rounded-lg lg:max-w-[900px] lg:mx-auto">
      <div className="flex justify-between items-center">
        <div className="flex justify-center items-center gap-2">
          {logoSrc && ( 
            <img src={logoSrc} alt="logosrcs" className="max-w-6 lg:aspect-square" />
          )}
          <div className="flex text-[8px]">
            <span className="font-thin">Promoção da loja <span className="font-bold">{store}</span></span>
          </div>
        </div>
        <span className="text-[8px]">{formatDate(createdAt!)}</span>
      </div>
      
      <div className="flex flex-col lg:grid lg:grid-cols-2">
        <img src={imageUrl} alt="linkdaimagem" className="rounded-lg max-w-[90%] mx-auto object-contain h-auto" />

        <div className="flex flex-col gap-4 mt-5">
          <h4 className="font-bold">{title}</h4>

          {coupon && (
            <div className="flex justify-between gap-2">
              <div className="flex items-center gap-2">
                <div className="bg-blue-500 p-1 flex justify-center items-center rounded-full text-white">
                  <LocalActivity fontSize="small" />
                </div>
                <span className="text-blue-500 font-semibold">Copie o Cupom:</span>
              </div>

              <button 
                onClick={(e) => fetchCoupon(e)}
                className="flex items-center flex-1 justify-between text-xs uppercase font-medium text-white bg-[#F67DAF] gap-2 px-2 py-2 rounded-xl"
              >
                {isCopied ? (
                  <span>Copiado!</span>
                ) : (
                  <span>{coupon.substring(coupon.indexOf(":") + 2)}</span>
                )}
                <ContentCopy fontSize="small" className="text-blue-500"  />
              </button>
            </div>
          )}

          <span className="font-extrabold text-[#F67DAF] text-lg">R$ {price}</span>

          <a 
            href={link} 
            className="bg-blue-600 flex items-center justify-between py-3 px-4 rounded-full"
          >
            <span className="text-white font-semibold text-lg ">Compre aqui</span>
            <div className="border-2 border-[#F67DAF] flex justify-center p-1 items-center rounded-full">
              <ArrowForwardIos fontSize="small" className="text-[#F67DAF]" />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ProductInfo;
