import React, { useEffect, useState } from "react";
import api from "./api/api";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { formatShopName } from "./utils/formatShopName";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import StoreLayout from "./layouts/StoreLayout";

interface Product {
  imageUrl: string;
  title: string;
  price: string;
  dialogs: string;
  coupon?: string;
  shipping?: string;
  logourl: string;
  id: number;
}

interface ShopData {
  nomedaloja: string;
  domainName: string;
  colorHeader: string;
  backgroundcolor: string;
  products: Product[];
  linkdogrupo: string;
  logourl: string;
}

const App: React.FC = () => {
  const [shopData, setShopData] = useState<ShopData | null>(null);
  const [produtos, setProdutos] = useState<Product | any>(Array);
  const shopName = "promosninja";

  useEffect(() => {
    api
      .get(`/lojas/${shopName}`)
      .then((response) => {
        const responses = response.data;
        console.log(responses); // Verifica os dados recebidos no console
        setShopData(responses);
      })
      .catch((error) => console.error("Erro ao buscar dados da loja:", error));
  }, [shopName]);

  useEffect(() => {
    api
      .get(`/produtos/${shopName}`)
      .then((response) => {
        const responses = response.data;
        setProdutos(responses);
      })
      .catch((error) => console.error("Erro ao buscar dados da loja:", error));
  }, []);

  const formattedShopName = shopData?.nomedaloja
    ? formatShopName(shopData.nomedaloja)
    : "Acesse minha loja";

  if (!shopData) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Helmet>
        <title>{formattedShopName}</title>
        <meta name="description" content={`${shopData.nomedaloja}`} />
        <meta property="image" content={shopData.logourl} />
        <meta property="og:url" content={window.location.href} />
        <link rel="icon" href={shopData.logourl} />
        <link rel="apple-touch-icon" href={shopData.logourl} />
      </Helmet>

      <StoreLayout
        shopName={shopData.nomedaloja}
        headerColor={shopData.colorHeader}
        backgroundcolor={shopData.backgroundcolor}
        produtos={produtos}
        linkdogrupo={shopData.linkdogrupo}
        logourl={shopData.logourl}
      />
    </>
  );
};

export default App;
