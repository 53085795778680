import { Helmet } from "react-helmet";
import "./TermsOfUse.css";

const TermsOfUse = () => {
  return (
    <div className="terms-of-use-container">
      <Helmet>
        <title>Termos de Uso</title>
      </Helmet>
      
      <h1>Termos de Uso</h1>
      <p>Bem-vindo à <strong>PromosNinja</strong>! Esta política de privacidade e termos de uso explicam como coletamos, usamos, protegemos suas informações pessoais e descrevem as condições de utilização dos nossos serviços.</p>
      
      <h2>1. Informações que Coletamos</h2>
      <h3>1.1. Informações Coletadas</h3>
      <p>Coletamos as seguintes informações pessoais:</p>
      <ul>
        <li>Nome</li>
        <li>Email</li>
        <li>Data de nascimento</li>
        <li>Endereço</li>
        <li>CPF</li>
        <li>Dados de navegação e uso do site</li>
      </ul>
      
      <h3>1.2. Finalidade da Coleta</h3>
      <p>As informações coletadas são utilizadas para:</p>
      <ul>
        <li>Fornecer e personalizar nossos serviços</li>
        <li>Enviar atualizações, promoções e comunicações relevantes</li>
        <li>Melhorar nossos produtos e serviços</li>
        <li>Realizar análises e pesquisas de mercado</li>
        <li>Cumprir obrigações legais</li>
      </ul>
      
      <h2>2. Compartilhamento de Informações</h2>
      <h3>2.1. Compartilhamento com Terceiros</h3>
      <p>Não compartilhamos suas informações pessoais com terceiros, exceto quando necessário para:</p>
      <ul>
        <li>Atender solicitações legais ou regulatórias</li>
        <li>Proteger nossos direitos, segurança e propriedade</li>
        <li>Operar nosso negócio com prestadores de serviço de confiança, sob contratos que garantam a confidencialidade</li>
      </ul>
      
      <h3>2.2. Segurança das Informações</h3>
      <p>Adotamos medidas de segurança para proteger suas informações pessoais contra acesso não autorizado, alteração, divulgação ou destruição. No entanto, nenhuma transmissão de dados pela internet é 100% segura, e não podemos garantir a segurança absoluta.</p>
      
      <h2>3. Direitos dos Usuários</h2>
      <p>Você tem o direito de:</p>
      <ul>
        <li>Acessar suas informações pessoais</li>
        <li>Corrigir informações incorretas ou desatualizadas</li>
        <li>Solicitar a exclusão de suas informações pessoais, exceto quando necessárias para cumprimento legal</li>
        <li>Restringir ou se opor ao processamento de suas informações pessoais</li>
        <li>Portar seus dados pessoais a outro fornecedor de serviços</li>
      </ul>
      
      <h2>4. Termos de Uso</h2>
      <h3>4.1. Uso Adequado dos Serviços</h3>
      <p>Ao utilizar a PromosNinja, você concorda em:</p>
      <ul>
        <li>Não utilizar os serviços para fins de spam, atividades ilícitas ou qualquer outra finalidade que viole as leis aplicáveis</li>
        <li>Respeitar os direitos de propriedade intelectual da PromosNinja e de terceiros</li>
        <li>Não tentar acessar áreas restritas da plataforma sem autorização</li>
      </ul>
      <p>Qualquer uso indevido poderá resultar na suspensão ou exclusão de sua conta.</p>
      
      <h2>5. Alterações neste Termo</h2>
      <p>Reservamo-nos o direito de atualizar estes termos de uso periodicamente. Notificaremos os usuários sobre alterações significativas por email ou através da plataforma. Recomendamos que você revise esta página regularmente para se manter informado.</p>
      
      <h2>6. Contato</h2>
      <p>Se você tiver dúvidas sobre esta política de privacidade ou sobre nossas práticas de proteção de dados, entre em contato conosco:</p>
      <p>Email: <a href="mailto:ninja@promosninjas.com.br">ninja@promosninjas.com.br</a></p>
      <p>Endereço: Rua Monte das Oliveiras, 296, Altos de Santana - São José dos Campos-SP</p>
    </div>
  );
};

export default TermsOfUse;
