import { Close, Search } from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import banner from "../assets/banner.png";
import logo from '../assets/logo.png';
import ProductCard from "../components/ProductCard";
import { formatShopName } from "../utils/formatShopName";

interface Product {
  imageUrl?: string;
  title?: string;
  price?: string;
  dialogs?: string;
  coupon?: string;
  shipping?: string;
  link?: string;
  id: number;
  createdAt: Date | string; // Campo criadoAt pode ser Date ou string
}

interface ProductLayoutProps {
  shopName: string;
  headerColor: string;
  backgroundcolor: string;
  produtos: Product[];
  linkdogrupo: string;
  logourl: string;
}

const StoreLayout: React.FC<ProductLayoutProps> = ({
  shopName,
  produtos,
}) => {
  const [displayedProducts, setDisplayedProducts] = useState<Product[]>([]);
  const [products, setProducts] = useState<Product[]>([])
  const [allProducts, setAllProducts] = useState<Product[]>([])
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [activeTab, setActiveTab] = useState('recentes');
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedQuery, setDebouncedQuery] = useState('');

  useEffect(() => {
    const initialItemsPerPage = 10;

    const convertToDate = (product: Product) => ({
      ...product,
      createdAt:
        typeof product.createdAt === "string"
          ? new Date(product.createdAt)
          : product.createdAt,
    });

    const sortedProducts = produtos
      .map(convertToDate)
      .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());
    setDisplayedProducts(sortedProducts.slice(0, initialItemsPerPage));
    setAllProducts(sortedProducts.slice(0, initialItemsPerPage))
    setProducts(sortedProducts)
  }, [produtos]);

  const loadMoreProducts = () => {
    if (loading) return;

    setLoading(true);

    setTimeout(() => {
      const itemsPerPage = 10;
      const nextPage = currentPage + 1;
      const startIndex = currentPage * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;

      // Converte createdAt para Date, se necessário
      const convertToDate = (product: Product) => ({
        ...product,
        createdAt:
          typeof product.createdAt === "string"
            ? new Date(product.createdAt)
            : product.createdAt,
      });

      const sortedProducts = produtos
        .map(convertToDate)
        .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());
      const moreProducts = sortedProducts.slice(startIndex, endIndex);

      setDisplayedProducts((prev) => [...prev, ...moreProducts]);
      setAllProducts((prev) => [...prev, ...moreProducts])
      setCurrentPage(nextPage);
      setLoading(false);
    }, 300);
  };

  const handleTabClick = (tab: string) => {
    setActiveTab(tab)
  }

  const toggleSearchVisibility = () => {
    setIsSearchVisible(prev => !prev)
    if (isSearchVisible) {
      setDisplayedProducts(allProducts)
      setSearchQuery('')
    }
  }

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedQuery(searchQuery)
    }, 500); // Delay de 500ms

    return () => clearTimeout(timeoutId)
  }, [searchQuery])

  useEffect(() => {
    if (debouncedQuery) {
      const filtered = products.filter((product) => (
        product.title?.toLocaleLowerCase().includes(debouncedQuery.toLocaleLowerCase())
      ))
      setDisplayedProducts(filtered)
    } else {
      setDisplayedProducts(allProducts)
    }
  }, [allProducts, debouncedQuery])

  return (
    <div className="bg-[#f0f2f5] min-h-screen flex flex-col items-center">
      <header className="bg-[#F67DAF] w-full py-5">
        <div className="w-full grid grid-cols-2 justify-between items-center px-5 lg:px-0 lg:max-w-[900px] lg:mx-auto">
          <Link to='/' className="flex items-center gap-2 hover:no-underline">
            <img src={logo} className="w-10 h-10" alt="Logo da Loja" />
            <h5 className="text-white font-medium">
              {formatShopName(shopName)}
            </h5>
          </Link>
          <button 
            onClick={toggleSearchVisibility} 
            className="text-white focus:outline-none text-right"
          >
            {isSearchVisible ? <Close /> : <Search />}
          </button>
      
          {isSearchVisible && (
            <form className="flex mt-5 items-center bg-white rounded-lg py-1 px-2 col-span-2 w-full transition-all ease-in-out duration-300">
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearchChange}
                placeholder="Pesquisar produtos..."
                className="w-full p-2 border-none rounded-lg focus:outline-none"
              />
            </form>
          )}
        </div>
      </header>

      <main className="flex flex-col w-full pb-5 lg:max-w-[900px]">
        <div className="grid grid-cols-3 w-full bg-[#F67DAF]">
          <button 
            className="text-sm text-black text-center outline-none pt-5 bg-[#f0f2f5] rounded-tl-full lg:rounded-none"
            onClick={() => handleTabClick('recentes')}
          >
            <span className={`pb-1 ${activeTab === 'recentes' && 'font-bold border-b-2 border-[#F67DAF] text-blue-500'}`}>Recentes</span>
          </button>
          <button 
            className="text-sm text-black text-center outline-none pt-5 bg-[#f0f2f5]"
            onClick={() => handleTabClick('destaques')}  
          >
            <span className={`pb-1 ${activeTab === 'destaques' && 'font-bold border-b-2 border-[#F67DAF] text-blue-500'}`}>Destaques</span>
          </button>
          <button 
            className="text-sm text-black text-center outline-none pt-5 bg-[#f0f2f5] rounded-tr-full lg:rounded-none"
            onClick={() => handleTabClick('cupons')}  
          >
            <span className={`pb-1 ${activeTab === 'cupons' && 'font-bold border-b-2 border-[#F67DAF] text-blue-500'}`}>Cupons</span>
          </button>
        </div>

        <a href="https://promosninjas.com.br/whatsapp" className="w-full px-5 pt-8 lg:px-0">
          <img src={banner} alt="banner do whatsapp" className="object-contain" />
        </a>

        {activeTab === 'recentes' && (
          <>
            {displayedProducts && displayedProducts.length > 0 ? (
              <div className="flex justify-center py-5">
                <div className="grid grid-cols-1 px-5 gap-5 md:grid-cols-2 lg:px-0">
                  {displayedProducts.map((product, index) => (
                    <ProductCard
                      key={index}
                      imageUrl={product.imageUrl || ""}
                      title={product?.title || ""}
                      price={product?.price || ""}
                      dialogs={product?.dialogs || ""}
                      coupon={product?.coupon || ""}
                      shipping={product?.shipping || ""}
                      link={product?.link}
                      id={product.id}
                    />
                  ))}
                </div>
              </div>
            ) : (
              <div className="h-48 flex justify-center items-center">
                <p>Sem novos produtos disponíveis no momento.</p>
              </div>
            )}
            <div className="flex justify-center w-full mt-1">
              {loading ? (
                <CircularProgress />
              ) : (
                <button
                  onClick={loadMoreProducts}
                  className="py-3 px-5 text-xs bg-zinc-200 text-blue-500 rounded-3xl uppercase font-semibold" 
                >
                  Olhar mais produtos
                </button>
              )}
            </div>
          </>
        )}
        {activeTab === 'destaques' && (
          <>
            {displayedProducts && displayedProducts.length > 0 ? (
              <div className="flex justify-center py-5">
                <div className="grid grid-cols-1 px-5 gap-5 md:grid-cols-2 lg:px-0">
                  {displayedProducts.map((product, index) => (
                    <ProductCard
                      key={index}
                      imageUrl={product.imageUrl || ""}
                      title={product?.title || ""}
                      price={product?.price || ""}
                      dialogs={product?.dialogs || ""}
                      coupon={product?.coupon || ""}
                      shipping={product?.shipping || ""}
                      link={product?.link}
                      id={product.id}
                    />
                  ))}
                </div>
              </div>
            ) : (
              <div className="h-48 flex justify-center items-center">
                <p>Sem novos produtos disponíveis no momento.</p>
              </div>
            )}
            <div className="flex justify-center w-full mt-1">
              {loading ? (
                <CircularProgress />
              ) : (
                <button
                  onClick={loadMoreProducts}
                  className="py-3 px-5 text-xs bg-zinc-200 text-blue-500 rounded-3xl uppercase font-semibold" 
                >
                  Olhar mais produtos
                </button>
              )}
            </div>
          </>
        )}
        {activeTab === 'cupons' && (
          <>
            {displayedProducts && displayedProducts.length > 0 ? (
              <div className="flex justify-center py-5">
                <div className="grid grid-cols-1 px-5 gap-5 md:grid-cols-2 lg:px-0">
                  {displayedProducts.map((product, index) => (
                    <ProductCard
                      key={index}
                      imageUrl={product.imageUrl || ""}
                      title={product?.title || ""}
                      price={product?.price || ""}
                      dialogs={product?.dialogs || ""}
                      coupon={product?.coupon || ""}
                      shipping={product?.shipping || ""}
                      link={product?.link}
                      id={product.id}
                    />
                  ))}
                </div>
              </div>
            ) : (
              <div className="h-48 flex justify-center items-center">
                <p>Sem novos produtos disponíveis no momento.</p>
              </div>
            )}
            <div className="flex justify-center w-full mt-1">
              {loading ? (
                <CircularProgress />
              ) : (
                <button
                  onClick={loadMoreProducts}
                  className="py-3 px-5 text-xs bg-zinc-200 text-blue-500 rounded-3xl uppercase font-semibold" 
                >
                  Olhar mais produtos
                </button>
              )}
            </div>
          </>
        )}
      </main>
    </div>
  );
};

export default StoreLayout;
