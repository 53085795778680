import { FavoriteBorder, WhatsApp } from "@mui/icons-material";
import React from "react";
import amazonLogo from "../assets/amzn.png";
import mercadolivreLogo from "../assets/livre.png";
import magaluLogo from "../assets/magalu.png";
import NaturaLogo from "../assets/Natura.png";
import shopeeLogo from "../assets/shopee.png";

interface ProductCardProps {
  imageUrl?: string;
  title?: string;
  price?: string;
  dialogs?: string;
  coupon?: string;
  shipping?: string;
  link?: string;
  id: number;
}

const ProductCard: React.FC<ProductCardProps> = ({
  imageUrl,
  title,
  price,
  dialogs,
  coupon,
  shipping,
  link,
  id,
}) => {
  const isMercadolivre = link?.includes("mercadolivre");
  const isShopee = link?.includes("shopee");
  const isMagalu = link?.includes("magazine");
  const isAmazon = link?.includes("amzn") || link?.includes("amazon");
  const isNatura = link?.includes("natura");

  let logoSrc;
  if (isMercadolivre) {
    logoSrc = mercadolivreLogo;
  } else if (isShopee) {
    logoSrc = shopeeLogo;
  } else if (isMagalu) {
    logoSrc = magaluLogo;
  } else if (isAmazon) {
    logoSrc = amazonLogo;
  } else if (isNatura) {
    logoSrc = NaturaLogo;
  }

  const shareMessage = () => {
    const message = encodeURIComponent(link!);
    const whatsappUrl = `https://wa.me/?text=${message}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <a href={`/produto/${id}`} className="hover:no-underline">
      <div className="bg-zinc-50 rounded-lg flex p-3 gap-3">
        <div className="relative">
          <div className="w-36 h-36">
            <img
              src={imageUrl}
              alt="imagseurl"
              className="w-full h-full rounded-lg"
            />
          </div>
          {logoSrc && (
            <div className="absolute bottom-2 left-2">
              <img src={logoSrc} alt="logosrc" className="w-6 h-6" />
            </div>
          )}
        </div>
        <div className="flex justify-between flex-col w-full">
          <h3 className="text-xs">{title}</h3>
          <div className="flex justify-between items-end gap-4 w-full">
            <div className="flex flex-col gap-1 flex-1">
              {coupon && (
                <span className="flex items-center text-[10px] uppercase font-medium text-white bg-[#F67DAF] gap-2 p-[4px] rounded-lg">
                  <span>{coupon.substring(coupon.indexOf(":") + 2)}</span>
                </span>
              )}
              
              <span className="text-[#F67DAF] text-base font-extrabold">R$ {price}</span>
            </div>
            <div className="flex flex-col gap-1">
              <div className="bg-green-500 rounded-full flex justify-center items-center p-1" onClick={shareMessage}>
                <WhatsApp className="text-white" fontSize="small"  />
              </div>
              <div className="bg-zinc-500 rounded-full flex justify-center items-center p-1">
                <FavoriteBorder className="text-white" fontSize="small" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

export default ProductCard;
